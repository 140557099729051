import { saveAs } from 'file-saver';
import React from 'react';
import { Button, Tabs } from 'antd';
import { HiOutlineDownload } from 'react-icons/hi';
import { FaCheckCircle } from 'react-icons/fa';
import JSZip from 'jszip';

// Função para baixar múltiplos documentos e criar um ZIP
export async function downloadDocuments(links) {
    
    if (!links || links.length === 0) return;

    const zip = new JSZip();
    const folder = zip.folder("Documentos");

    const promises = Object.entries(links[0]).map(async ([key, link]) => {
        const response = await fetch(`https://${link}`);
        const blob = await response.blob();
        folder.file(`${key}.pdf`, blob); // Nomeia o arquivo com base na chave
    });

    await Promise.all(promises);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, "Documentos.zip");
}

export default function DownloadDocumentos({ linkDownload }) {
    // Validação para evitar erros caso linkDownload seja undefined ou vazio
    if (!linkDownload || linkDownload.length === 0) {
        return <p>Sem documentos para exibir.</p>;
    }

    const handleDownloadAll = () => {
        if (linkDownload && linkDownload.length > 0) {
            downloadDocuments(linkDownload);
        }
    };

    const hasMultipleDocuments = linkDownload && Object.keys(linkDownload[0]).length > 1;

    return (
        <>
            <div className="card grid gap-4">
                <div className="flex gap-4 justify-between">
                    <p className="flex items-center gap-4">
                        <FaCheckCircle size={20} style={{ color: '#0D9F57' }} />
                        <span className="text-sm font-normal text-white">
                            {`Documento${hasMultipleDocuments ? 's' : ''} gerado${hasMultipleDocuments ? 's' : ''} com sucesso! Valide todos os campos:`}
                        </span>
                    </p>
                    {hasMultipleDocuments && (
                        <Button
                            className="flex items-center shadow-none font-bold text-black bg-white"
                            icon={<HiOutlineDownload />}
                            onClick={handleDownloadAll}
                        >
                            Download dos Documentos
                        </Button>
                    )}
                </div>

                <Tabs>
                    {Object.entries(linkDownload[0]).map(([key, link], index) => (
                        <Tabs.TabPane
                            tab={<span>{key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}</span>}
                            key={index}
                        >
                            <iframe
                                src={`https://${link}`}
                                title={`PDF Viewer - ${key}`}
                                width="100%"
                                height="800px"
                                style={{ border: "none" }}
                            ></iframe>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        </>
    );
}
