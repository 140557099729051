import React, { useState, useEffect } from 'react';
import { Button, Modal, message } from 'antd';
import { FiCopy, FiCheck, FiExternalLink } from 'react-icons/fi';
import { MdOutlineEmail, MdShare } from 'react-icons/md';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa6';
// import PartilharComClienteee from '../PerfilProposta/EditarProposta/partilhar-com-cliente';

export default function PartilharClienteProposta({ propostaInfo, dataCarros, valoresTotais, titulo, formatarNumero, dataUser }) {

    console.log(dataUser)

    const { t } = useTranslation();
    const [copiedItem, setCopiedItem] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false); //modal para os ctas de copiar textos 


    // console.log(valoresTotais)

    // console.log('as')

    //valores para propostas para Empresa
    const [taxaDeServicoSemIva, setTaxaDeServicoSemIva] = useState(null);
    const [precoFinalChaveNaMaoSIva, setPrecoFinalChaveNaMaoSIva] = useState(null);
    const [precoFinalChaveNaMaoCIva, setPrecoFinalChaveNaMaoCIva] = useState(null);
    const [valorTotalCaucaoParticular, setValorTotalCaucaoParticular] = useState(null);
    const [valorTotalCaucaoEmpresa, setValorTotalCaucaoEmpresa] = useState(null);


    // console.log(valoresTotais.taxaDeFinanciamentoAtivo)


    useEffect(() => {
        if (valoresTotais) {

            const taxaDeServicoSemIva = valoresTotais.taxaDeServico / 1.23;
            setTaxaDeServicoSemIva(taxaDeServicoSemIva);

            //valores com iva - para Particular
            let precoChaveNaMaoComIva =
                valoresTotais.precoViaturaComIva +
                valoresTotais.taxaDeServico +
                valoresTotais.valorTotalISV;


            if (valoresTotais.taxaDeFinanciamentoAtivo) {
                precoChaveNaMaoComIva += valoresTotais.taxaDeFinanciamento;
            }

            setPrecoFinalChaveNaMaoCIva(precoChaveNaMaoComIva);

            const precoCaucaoComIva = precoChaveNaMaoComIva * 0.2
            setValorTotalCaucaoParticular(precoCaucaoComIva)

            //valores sem iva - para Empresa
            let precoChaveNaMaoSemIva =
                (valoresTotais.precoViaturaSemIva + valoresTotais.taxaDeServico) +
                valoresTotais.valorTotalISV;

            if (valoresTotais.taxaDeFinanciamentoAtivo) {
                precoChaveNaMaoSemIva += valoresTotais.taxaDeFinanciamento;
            }

            //console.log(precoChaveNaMaoSemIva)

            setPrecoFinalChaveNaMaoSIva(precoChaveNaMaoSemIva);

            const precoCaucaoSemIva = precoChaveNaMaoSemIva * 0.2
            setValorTotalCaucaoEmpresa(precoCaucaoSemIva)
        }
    }, [valoresTotais]);





    const handleOptionClick = (value) => {
        let text;

        if (value === 1) {
            text = (
                <div>
                    <div>
                        <a target="_blank" style={{ cursor: "pointer" }} href={propostaInfo.linkDaProposta}>
                            {`${propostaInfo.outros.nome_proposta}`}
                        </a>
                    </div>
                    <div>{t('partilhar_email.preco_carro')}: {`${formatarNumero(valoresTotais.precoViaturaComIva)}`} {t('partilhar_email.por_negociar')}</div>
                    <div>
                        {`${t('partilhar_email.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                            ? t('gerar_proposta.isento')
                            : formatarNumero(valoresTotais.valorTotalISV)
                            }`}
                    </div>
                    <div>{`${t('partilhar_email.despesas_de_importacao')}: ${formatarNumero(valoresTotais.taxaDeServico)}`}</div>
                    {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                        <div>
                            {t('partilhar_email.taxa_financiamento')}: {formatarNumero(valoresTotais.taxaDeFinanciamento)}
                        </div>
                    )}
                    <div><b>{`${t('partilhar_email.preco_final')}: ${formatarNumero(precoFinalChaveNaMaoCIva)}`}</b></div>
                    {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                        <div>
                            {t('partilhar_email.caucao')}: {formatarNumero(valorTotalCaucaoParticular)}
                        </div>
                    )}
                </div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });


        } else if (value === 4) {
            text = (
                <div>
                    <div>
                        <a target="_blank" style={{ cursor: "pointer" }} href={propostaInfo.linkDaProposta}>
                            {`${propostaInfo.outros.nome_proposta}`}
                        </a>
                    </div>
                    <div>{t('partilhar_email.preco_carro')}: {`${formatarNumero(valoresTotais.precoViaturaSemIva)}`} {t('partilhar_email.por_negociar_en')}</div>
                    <div>
                        {`${t('partilhar_email.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                            ? t('gerar_proposta.isento')
                            : formatarNumero(valoresTotais.valorTotalISV)
                            }`}
                    </div>
                    <div>{`${t('partilhar_email.despesas_de_importacao')}: ${formatarNumero(valoresTotais.taxaDeServico)}`}</div>
                    {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                        <div>
                            {t('partilhar_email.taxa_financiamento')}: {formatarNumero(valoresTotais.taxaDeFinanciamento)}
                        </div>
                    )}
                    <div><b>{`${t('partilhar_email.preco_final_chave_mao')}: ${formatarNumero(precoFinalChaveNaMaoSIva)}`}</b></div><br></br>
                    {valoresTotais.taxaDeFinanciamentoAtivo == 1 && (
                        <div>
                            {t('partilhar_email.caucao')}: {formatarNumero(valorTotalCaucaoEmpresa)}
                        </div>
                    )}
                </div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });



        } else if (value === 5) {

            text = (
                <div>
                    <div>
                        <a target="_blank" style={{ cursor: "pointer" }} href={propostaInfo.linkDaProposta}>
                            {`${propostaInfo.outros.nome_proposta}`}
                        </a>
                    </div>
                    <div>{`${t('partilhar_email.preco_carro_en')}: ${formatarNumero(valoresTotais.precoViaturaComIva)}`} {t('partilhar_email.por_negociar')}</div>
                    <div>
                        {`${t('gerar_proposta.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                            ? t('gerar_proposta.isento')
                            : formatarNumero(valoresTotais.valorTotalISV)
                            }`}
                    </div>
                    <div>{`${t('partilhar_email.despesas_de_importacao_en')}: ${formatarNumero(valoresTotais.taxaDeServico)}`}</div>
                    <div><b>{`${t('partilhar_email.preco_final_chave_mao_en')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoParticular)}`}</b></div>
                </div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else if (value === 6) {

            text = (
                <div>
                    <div>
                        <a target="_blank" style={{ cursor: "pointer" }} href={propostaInfo.linkDaProposta}>
                            {`${propostaInfo.outros.nome_proposta}`}
                        </a>
                    </div>
                    <div>{`${t('partilhar_email.preco_carro_en')}: ${formatarNumero(valoresTotais.precoViaturaSemIva)}`} {t('partilhar_email.por_negociar_en')}</div>
                    <div>
                        {`${t('gerar_proposta.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                            ? t('gerar_proposta.isento')
                            : formatarNumero(valoresTotais.valorTotalISV)
                            }`}
                    </div>
                    <div>{`${t('partilhar_email.despesas_de_importacao_en')}: ${formatarNumero(valoresTotais.taxaDeServico)}`}</div>
                    <div><b>{`${t('partilhar_email.preco_final_chave_mao_en')}: ${formatarNumero(precoFinalChaveNaMaoSIva)}`}</b></div>
                    {/* <div><b>{`${t('partilhar_email.preco_final_chave_mao_c_iva_en')}: ${formatarNumero(precoFinalChaveNaMaoCIva)}`}</b></div> */}
                </div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });


        } else if (value === 2) {

            let text = (
                <div><a href={propostaInfo.linkDaProposta}>{`${propostaInfo.linkDaProposta}`}</a></div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else if (value === 3) {

            const text = `${propostaInfo.linkDaProposta}`;

            const blob = new Blob([text], { type: 'text/plain' });

            navigator.clipboard.write([new ClipboardItem({ "text/plain": blob })])
                .then(() => {
                    // Copied successfully
                })
                .catch((error) => {
                    // Handle errors
                });

        } else if (value === 7) {

            const text = `*${dataCarros.titulo}*          
${t('partilhar_email.preco_carro')}: ${formatarNumero(valoresTotais.precoViaturaComIva)} ${dataUser.language === "es" ? "_(POR NEGOCIAR)_" : ""}
${t('partilhar_email.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                    ? t('gerar_proposta.isento')
                    : formatarNumero(valoresTotais.valorTotalISV)
                }
${t('partilhar_email.despesas_de_importacao')}: ${formatarNumero(valoresTotais.taxaDeServico)}
${t('partilhar_email.preco_final')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoParticular)}
*${t('gerar_proposta.link_proposta')}: ${propostaInfo.linkDaProposta}*`;

            // Copiar o texto para o clipboard
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log("Texto copiado com sucesso!");
                })
                .catch((error) => {
                    console.error("Erro ao copiar o texto:", error);
                });

        } else if (value === 8) {

            const text = `*${dataCarros.titulo}*
${t('partilhar_email.preco_carro')}: ${formatarNumero(valoresTotais.precoViaturaSemIva)} ${dataUser.language === "es" ? "_(POR NEGOCIAR)_" : ""}
${t('partilhar_email.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                    ? t('gerar_proposta.isento')
                    : formatarNumero(valoresTotais.valorTotalISV)
                }
${t('partilhar_email.despesas_de_importacao')}: ${formatarNumero(valoresTotais.taxaDeServico)}
${t('partilhar_email.preco_final_chave_mao')}: ${formatarNumero(precoFinalChaveNaMaoSIva)}
*${t('gerar_proposta.link_proposta')}: ${propostaInfo.linkDaProposta}*`;

            // Copiar o texto para o clipboard
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log("Texto copiado com sucesso!");
                })
                .catch((error) => {
                    console.error("Erro ao copiar o texto:", error);
                });

        } else if (value === 9) {

            const text = `*${dataCarros.titulo}*
${t('partilhar_email.preco_carro_en')}: ${formatarNumero(valoresTotais.precoViaturaComIva)} ${dataUser.language === "es" ? "_(TO BE NEGOTIATED)_" : ""}
${t('gerar_proposta.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                    ? t('gerar_proposta.isento')
                    : formatarNumero(valoresTotais.valorTotalISV)
                }
${t('partilhar_email.despesas_de_importacao_en')}: ${formatarNumero(valoresTotais.taxaDeServico)}
${t('partilhar_email.preco_final_chave_mao_en')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoParticular)}
*${t('gerar_proposta.link_proposta_en')}: ${propostaInfo.linkDaProposta}*`;

            // Copiar o texto para o clipboard
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log("Texto copiado com sucesso!");
                })
                .catch((error) => {
                    console.error("Erro ao copiar o texto:", error);
                });

        } else if (value === 10) {

            const text = `*${dataCarros.titulo}*
${t('partilhar_email.preco_carro_en')}: ${formatarNumero(valoresTotais.precoViaturaSemIva)} ${dataUser.language === "es" ? "_(TO BE NEGOTIATED)_" : ""}
${t('gerar_proposta.isv')}: ${valoresTotais.valorTotalISV == "0" || valoresTotais.valorTotalISV == 0
                    ? t('gerar_proposta.isento')
                    : formatarNumero(valoresTotais.valorTotalISV)
                }
${t('partilhar_email.despesas_de_importacao_en')}: ${formatarNumero(valoresTotais.taxaDeServico)}
${t('partilhar_email.preco_final_chave_mao_c_iva_en')}: ${formatarNumero(valoresTotais.valorPrecoChaveNaMaoEmpresa)}
*${t('gerar_proposta.link_proposta_en')}: ${propostaInfo.linkDaProposta}*`;

            // Copiar o texto para o clipboard
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log("Texto copiado com sucesso!");
                })
                .catch((error) => {
                    console.error("Erro ao copiar o texto:", error);
                });

        } else {

        }

    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const handleCopy = (value) => {

        handleOptionClick(value);
        setCopiedItem(value); // Define o item copiado~
        message.success('Copiado')
        setTimeout(() => {
            setCopiedItem(null); // Limpa o estado de copiado após 2 segundos
        }, 2000);
    };

    return (
        <div className={`flex items-center ${titulo ? 'gap-4' : 'gap-1'}`}>
            {/* <Popover
                content={ */}
            <Modal
                title={t('gerar_proposta.partilhar_com_cliente')}
                open={isModalVisible}
                onOk={handleCancel}
                onCancel={handleCancel}
                okText="Fechar"
                footer={null}
            >

                <div className="text-black grid gap-3 mt-6">

                    {propostaInfo.tipoProposta !== "proposta_cliente" && (
                        <>
                            <div className='p-3 bg-red-300 rounded border border-red-600 text-sm font-regular mb-6'>
                                <span>{t('gerar_proposta.alerta_na_partilha')}</span>
                            </div>
                        </>
                    )}


                    <div className='flex items-center gap-2 p-2 rounded bg-[#d6d6d6]'>
                        <MdOutlineEmail className="text-2xl text-black" /><span className='font-semibold'>Email</span>
                    </div>
                    <hr></hr>
                    {/* Copiar Texto para Email */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 1 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_email_particular')}
                                    onClick={() => handleCopy(1)}
                                />
                            )}
                            <span onClick={() => handleCopy(1)}>{t('gerar_proposta.copiar_texto_para_email_particular')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 4 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_email_empresa')}
                                    onClick={() => handleCopy(4)}
                                />
                            )}
                            <span onClick={() => handleCopy(4)}>{t('gerar_proposta.copiar_texto_para_email_empresa')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 5 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_email_en_particular')}
                                    onClick={() => handleCopy(5)}
                                />
                            )}
                            <span onClick={() => handleCopy(5)}>{t('gerar_proposta.copiar_texto_para_email_en_particular')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    {/* Copiar Texto para Email em Inglês */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 6 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_email_en_empresa')}
                                    onClick={() => handleCopy(6)}
                                />
                            )}
                            <span onClick={() => handleCopy(6)}>{t('gerar_proposta.copiar_texto_para_email_en_empresa')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    {/* Copiar URL com Link */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 2 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_url_com_link')}
                                    onClick={() => handleCopy(2)}
                                />
                            )}
                            <span onClick={() => handleCopy(2)}>{t('gerar_proposta.copiar_url_com_link')}</span>
                        </label>
                    </div>

                    <div className='mt-6 flex items-center gap-2 p-2 rounded bg-[#d6d6d6]'>
                        <FaWhatsapp className="text-2xl text-black" /><span className='font-semibold'>Whatsapp</span>
                    </div>
                    <hr></hr>
                    {/* Copiar URL simples */}
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 3 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_url')}
                                    onClick={() => handleCopy(3)}
                                />
                            )}
                            <span onClick={() => handleCopy(3)}>{t('gerar_proposta.copiar_url')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 7 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_whats_particular')}
                                    onClick={() => handleCopy(7)}
                                />
                            )}
                            <span onClick={() => handleCopy(7)}>{t('gerar_proposta.copiar_texto_para_whats_particular')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 8 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_whats_empresa')}
                                    onClick={() => handleCopy(8)}
                                />
                            )}
                            <span onClick={() => handleCopy(8)}>{t('gerar_proposta.copiar_texto_para_whats_empresa')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 9 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_whats_en_particular')}
                                    onClick={() => handleCopy(9)}
                                />
                            )}
                            <span onClick={() => handleCopy(9)}>{t('gerar_proposta.copiar_texto_para_whats_en_particular')}</span>
                        </label>
                    </div>
                    <hr></hr>
                    <div className="flex items-center gap-2">
                        <label className="flex items-center gap-2 cursor-pointer">
                            {copiedItem === 10 ? (
                                <FiCheck className="text-2xl text-black" title="Copiado!" />
                            ) : (
                                <FiCopy
                                    className="text-2xl text-black"
                                    title={t('gerar_proposta.copiar_texto_para_whats_en_empresa')}
                                    onClick={() => handleCopy(10)}
                                />
                            )}
                            <span onClick={() => handleCopy(10)}>{t('gerar_proposta.copiar_texto_para_whats_en_empresa')}</span>
                        </label>
                    </div>
                </div>


            </Modal>

            <Button
                type="primary"
                onClick={showModal}
                className='shadow-none font-bold text-black bg-white'>
                <div className='flex items-center gap-2'>
                    <MdShare />
                    {titulo ? t('gerar_proposta.partilhar_com_cliente') : null}
                </div>
            </Button>



            <Button
                type="primary"
                onClick={() => window.open(propostaInfo.linkDaProposta, '_blank')}
                className='shadow-none font-bold text-black bg-white'>
                <div className='flex items-center gap-2'>
                    <FiExternalLink />
                    {titulo ? t('gerar_proposta.abrir') : null}
                </div>
            </Button>
        </div>
    );
}
