import React, { useState, useEffect, useCallback } from 'react';
import TitleSection from "../../components/Elements/Title";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Checkbox, Collapse, message } from "antd";
import axios from "axios";
import InputCalculoProposta from "../../components/CalculoProposta/InputCalculoProposta";
import { validateOperation } from '@apollo/client/link/utils';

const { Option } = Select;
const { Panel } = Collapse;

export default function SimuladorISV({ dataUser }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    const [loading, setLoading] = useState(false);
    const [urlCarroLink, setUrlCarroLink] = useState('');
    const [propostaInfo, setPropostaInfo] = useState({
        erro: ''
    });
    const [isvIucData, setIsvIucData] = useState({
        cilindrada: "",
        novoUsado: "",
        tipoCombustivel: "",
        emissoesCO2: "",
        particulas: 0,
        dataPrimeiroRegisto: "",
        hibridoPlugIn: false,
        wltp: false,
        tipoDeVeiculoParaOutros: "LigeiroPassageiros",
        tipoDeVeiculoParaInsencoes: ""
    });

    const [valoresTotais, setValoresTotais] = useState({
        valorTotalISV: null,
        valorTotalIUC: null,
        // valorTotalISV2025: null,
    });

    const fetchData = useCallback(async (url) => {
        setLoading(true);
        try {
            const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(url)}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;
            const response = await axios.get(requestUrl);

            if (response.data.sucesso) {
                const calculo = response.data.data.simulacao;

                let tipoCombustivel = calculo.combustivel_para_simulacao;
                if (tipoCombustivel === "Híbrido (Gasolina/Eletrico)") {
                    tipoCombustivel = "Gasolina";
                } else if (tipoCombustivel === "Híbrido (Gasóleo/Eletrico)") {
                    tipoCombustivel = "Gasoleo";
                }

                const allData = {
                    cilindrada: calculo.cilindrada_numerico,
                    novoUsado: calculo.novo === 1 ? "Novo" : "Usado",
                    tipoCombustivel,
                    emissoesCO2: calculo.emissoes_numerico,
                    hibridoPlugIn: calculo.hibrido,
                    wltp: calculo.wltp,
                    particulas: calculo.tipo_combustivel_traduzido === "Gasoleo" ? "1" : "0",
                    dataPrimeiroRegisto: calculo.data_registo_para_simulacao,
                    tipoDeVeiculoParaOutros: calculo.tipo_de_veiculo,
                    tipoDeVeiculoParaInsencoes: ''
                };

                setIsvIucData(allData);
            } else {
                message.error(response.data.mensagem);
                setUrlCarroLink('');
                setLoading(false);
            }
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        } finally {
            setLoading(false);
        }
    }, [dataUser]);

    useEffect(() => {
        const fetchISVIUCData = async () => {
            try {
                const novoUsadoInt = isvIucData.novoUsado === "Novo" ? 1 : 0;
                const tipoDeTestes = isvIucData.wltp ? 'WLTP' : 'NEDC';
                const tipoDeCombustivel = isvIucData.tipoCombustivel.includes("Híbrido")
                    ? isvIucData.tipoCombustivel.includes("Gasolina") ? "Gasolina" : "Gasoleo"
                    : isvIucData.tipoCombustivel;
                const tipoDeVeiculoFinal = isvIucData.hibridoPlugIn ? "HibridosPlugIn" : isvIucData.tipoDeVeiculoParaOutros;

                const pedidoISV = `${process.env.REACT_APP_PEDIDO_ISV}?novo=${novoUsadoInt}&tipo_de_testes_co2=${tipoDeTestes}&tipo_carro=${tipoDeVeiculoFinal}&combustivel=${tipoDeCombustivel}&particulas=${isvIucData.particulas}&co2=${isvIucData.emissoesCO2}&cilindrada=${isvIucData.cilindrada}&ano=${isvIucData.dataPrimeiroRegisto}&isencao=${isvIucData.tipoDeVeiculoParaInsencoes}`;
                // const pedidoISV2025 = `${process.env.REACT_APP_PEDIDO_ISV}?novo=${novoUsadoInt}&tipo_de_testes_co2=${tipoDeTestes}&tipo_carro=${tipoDeVeiculoFinal}&combustivel=${tipoDeCombustivel}&particulas=${isvIucData.particulas}&co2=${isvIucData.emissoesCO2}&cilindrada=${isvIucData.cilindrada}&ano=${isvIucData.dataPrimeiroRegisto}&isencao=${isvIucData.tipoDeVeiculoParaInsencoes}&ano_isv=2025`;
                const pedidoIUC = `${process.env.REACT_APP_PEDIDO_IUC}?cilindrada=${isvIucData.cilindrada}&ano=${isvIucData.dataPrimeiroRegisto}&combustivel=${tipoDeCombustivel}&co2=${isvIucData.emissoesCO2}&tipo_carro=${tipoDeVeiculoFinal}&tipo_de_testes_co2=${tipoDeTestes}&novo=${novoUsadoInt}`;

                const [isvResponse, iucResponse] = await Promise.all([
                    axios.get(pedidoISV),
                    // axios.get(pedidoISV2025),
                    axios.get(pedidoIUC)
                ]);

                setValoresTotais({
                    valorTotalISV: isvResponse.data.data.isv,
                    valorTotalIUC: iucResponse.data.data.iuc,
                    // valorTotalISV2025: isv2025Response.data.data.isv,
                });


                if (isvResponse.data.data.mensagem.avisos_isencao.length !== 0) {

                    message.warning(isvResponse.data.data.mensagem.avisos_isencao)

                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        erro: isvResponse.data.data.mensagem.avisos_isencao,
                    }));

                } else {

                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        erro: '',
                    }));

                }


            } catch (error) {
                console.error("Erro ao buscar ISV ou IUC:", error);
            }
        };

        if (isvIucData.cilindrada &&
            isvIucData.novoUsado &&
            isvIucData.tipoCombustivel &&
            isvIucData.emissoesCO2
        ) {
            fetchISVIUCData();
        }
    }, [isvIucData]);

    useEffect(() => {
        if (urlCarroLink) {
            fetchData(urlCarroLink);
        }
    }, [urlCarroLink, fetchData]);

    const onFormChange = (allValues) => {
        setIsvIucData({ ...isvIucData, ...allValues });
    };

    useEffect(() => {

        form.setFieldsValue({
            ...isvIucData,
            wltp: isvIucData?.wltp, // Garante que o valor do checkbox seja booleano
            hibridoPlugIn: isvIucData?.hibridoPlugIn,
        });

        // Valida manualmente os campos quando os valores são inválidos
        if (isvIucData.emissoesCO2 == '0' || isvIucData.emissoesCO2 === 0 || isvIucData.emissoesCO2 === "") {
            form.validateFields(['emissoesCO2']);
        }
        if (isvIucData.cilindrada == '0' || isvIucData.cilindrada === 0 || isvIucData.cilindrada === "") {
            form.validateFields(['cilindrada']);
        }
        if (isvIucData.dataPrimeiroRegisto == "" || !isvIucData.dataPrimeiroRegisto) {
            form.validateFields(['dataPrimeiroRegisto']);
        }

    }, [isvIucData, form]);

    return (
        <>
            <div className='card grid gap-4'>
                <TitleSection
                    title={t('simulador_isv.titulo_principal')}
                    description={t('simulador_isv.sub_titulo')}
                />

                <Input
                    placeholder={t('simulador.pesquisa')}
                    value={urlCarroLink}
                    onChange={(e) => setUrlCarroLink(e.target.value)}
                />

                <Form
                    layout="vertical"
                    form={form}
                    onValuesChange={(_, allValues) => onFormChange(allValues)}
                    className="form-de-envio-docs"
                >
                    <div className="grid gap-4">
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-white'>
                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <p className='text-white font-semibold'>Cilindrada</p>
                                    <Form.Item name="cilindrada" rules={[{ required: true, message: 'Insira um valor válido para a cilindrada!' }]}>
                                        <Input placeholder="Cilindrada" />
                                    </Form.Item>
                                </div>
                            )}

                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Estado</p>
                                <Form.Item name="novoUsado" rules={[{ required: true, message: 'Selecione o estado!' }]}>
                                    <Select placeholder="Estado">
                                        <Option value="1">Novo</Option>
                                        <Option value="0">Usado</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Tipo de Combustível</p>
                                <Form.Item name="tipoCombustivel" rules={[{ required: true, message: 'Selecione o tipo de combustível!' }]}>
                                    <Select placeholder="Tipo de Combustível">
                                        <Option value="Gasolina">Gasolina</Option>
                                        <Option value="Gasoleo">Gasoleo</Option>
                                        <Option value="Eletrico">Elétrico</Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <p className='text-white font-semibold'>Emissões CO2 (g/km)</p>
                                    <Form.Item name="emissoesCO2" rules={[{ required: true, message: 'Insira as emissões de CO2!' }]}>
                                        <Input type='number' placeholder="Emissões CO2 (g/km)" />
                                    </Form.Item>
                                </div>
                            )}

                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <Form.Item name="hibridoPlugIn" valuePropName="checked">
                                        <div className='flex items-center gap-2'>
                                            <Checkbox checked={isvIucData?.hibridoPlugIn == "1"} />
                                            <p className='text-white font-semibold'>Híbrido Plug-In</p>
                                        </div>
                                    </Form.Item>
                                </div>
                            )}

                            {isvIucData.tipoCombustivel !== "Eletrico" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <Form.Item
                                        name="wltp"
                                        valuePropName="checked"
                                    >
                                        <div className='flex items-center gap-2'>
                                            <Checkbox checked={isvIucData?.wltp == "1"} />  {/* Verifique se wltp é igual a "1" */}
                                            <p className='text-white font-semibold'>WLTP</p>
                                        </div>
                                    </Form.Item>
                                </div>
                            )}

                            <div className='flex flex-col items-stretch gap-2'>
                                <p className='text-white font-semibold'>Data 1º Registo</p>
                                <Form.Item name="dataPrimeiroRegisto" rules={[{ required: true, message: 'Insira a data do primeiro registo!' }]}>
                                    <Input placeholder="Data 1º Registo" />
                                </Form.Item>
                            </div>

                            {isvIucData.tipoCombustivel == "Gasoleo" && (
                                <div className='flex flex-col items-stretch gap-2'>
                                    <p className='text-white font-semibold'>Particulas</p>
                                    <Form.Item name="particulas" rules={[{ required: true, message: 'Selecione as partículas!' }]}>
                                        <Select placeholder="Particulas">
                                            <Option value="0">&lt; 0,001 g/km</Option>
                                            <Option value="1">&gt;= 0,001 g/km</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    </div>

                    <Collapse className="text-white">
                        <Panel header="Mais Detalhes" key="1">
                            <div className='grid gap-4'>
                                <div className='grid gap-2'>
                                    <p>Tipo de Veículo</p>
                                    <Form.Item name="tipoDeVeiculoParaOutros">
                                        <Select
                                            placeholder="Tipo de Veículo"
                                            value={isvIucData.tipoDeVeiculoParaOutros || "LigeiroPassageiros"}
                                        >
                                            <Option value="LigeiroPassageiros">Ligeiro de Passageiros</Option>
                                            <Option value="LSup2500kg">Ligeiro {'>'} 2500Kg</Option>
                                            <Option value="LMeMerc">Ligeiro Misto (cx 145x130), Mercadorias 4x2</Option>
                                            <Option value="LMercCF">Ligeiro Mercadorias Caixa Fechada</Option>
                                            <Option value="LMerc3Lug">Ligeiro Mercadorias 3 Lugares</Option>
                                            <Option value="LMerc4x4">Ligeiro Mercadorias 4x4</Option>
                                            <Option value="LMerc4x2PB3500">Ligeiro Mercadorias, Cx.Fechada s/Cab. Integrada, PB=3500kg</Option>
                                            <Option value="LMercNT">Ligeiro Mercadorias Não Tributado pela Tabela B</Option>
                                            <Option value="LPGPL">Ligeiro Passageiros a GPL</Option>
                                            <Option value="LPGN">Ligeiro Passageiros a Gás Natural</Option>
                                            <Option value="Taxi">Táxi</Option>
                                            <Option value="Autocaravana">Autocaravana</Option>
                                            <Option value="MotoTriQuad">Motociclo, Triciclo, Quadriciclo</Option>
                                        </Select>

                                    </Form.Item>
                                </div>

                                <div className='grid gap-2'>
                                    <p>Isenção</p>
                                    <Form.Item name="tipoDeVeiculoParaInsencoes">
                                        <Select placeholder="Isenção" value={isvIucData.tipoDeVeiculoParaInsencoes || ""}>
                                            <Option value="">Sem Isenção</Option>
                                            <Option value="familias_numerosas">Famílias numerosas</Option>
                                            <Option value="mudanca_residencia">Mudança de residência</Option>
                                            <Option value="pessoas_com_deficiência">Pessoas com deficiência</Option>
                                            <Option value="pessoas_com_deficiência_obriga_caixa_automatica">Pessoas com deficiência que obriga caixa automática</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                </Form>

                {propostaInfo.erro && (
  <span className='text-sm text-[#fffd00] opacity-90' dangerouslySetInnerHTML={{ __html: propostaInfo.erro }} /> 
                  )}

                {valoresTotais.valorTotalISV && (
                    <div className="border-card bg-black p-4 grid gap-4">
                        <p className="text-white text-lg font-bold">Resultado da Simulação:</p>
                        {/* <div className='grid gap-4'> */}
                        <div className="grid w-full gap-4">
                            <div className="flex items-center justify-between gap-2 bg-[#27272A] text-white p-2.5 rounded border border-[#FFFFFF1A]">
                                <p className="text-[1.2rem]">ISV</p>
                                <span className="text-[1.5rem] font-bold">{formatarNumero(valoresTotais.valorTotalISV)}</span>
                            </div>
                            {/* <div className="flex items-center justify-between gap-2 bg-[#27272A] text-white p-2.5 rounded border border-[#FFFFFF1A]">
                                <p className="text-[1.2rem]">ISV 2025</p>
                                <span className="text-[1.5rem] font-bold">{formatarNumero(valoresTotais.valorTotalISV2025)}</span>
                            </div>
                            {valoresTotais.valorTotalISV !== valoresTotais.valorTotalISV2025 ? (
                                valoresTotais.valorTotalISV < valoresTotais.valorTotalISV2025 ? (
                                    <p className="flex items-center gap-2 justify-center text-white text-lg my-4 text-center">Se comprar a viatura em 2024 consegue poupar {formatarNumero(valoresTotais.valorTotalISV2025 - valoresTotais.valorTotalISV)} <span className='text-2xl'>🤑</span></p>
                                ) : (
                                    <p className="flex items-center gap-2 justify-center text-white text-lg my-4 text-center">Se comprar a viatura em 2025 consegue poupar {formatarNumero(valoresTotais.valorTotalISV - valoresTotais.valorTotalISV2025)} <span className='text-2xl'>🤑</span></p>
                                )
                            ) : (
                                <p className="flex items-center gap-2 justify-center text-white text-lg my-4 text-center">O valor do ISV atual é igual ao valor do ISV para 2025.</p>
                            )} */}
                            <hr></hr>
                            <div className="flex items-center justify-between gap-2 bg-[#27272A] text-white p-2.5 rounded border border-[#FFFFFF1A]">
                                <p className="text-[1.2rem]">IUC</p>
                                <span className="text-[1.5rem] font-bold">{formatarNumero(valoresTotais.valorTotalIUC)}</span>
                            </div>

                        </div>
                    </div>
                )}
            </div >
        </>
    );
}
