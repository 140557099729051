import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Select, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiRefresh } from 'react-icons/hi';

const { Option } = Select;

const RegistarPropostaForm = ({ isvIucData, valoresTotais, setUrlCarroLink, dataUser, linkToken }) => {

    const { t } = useTranslation();

    const navigate = useNavigate(); // Hook para navegação


    const [form] = Form.useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); //bloqueia o button de criar proposta
    const [propostaNome, setPropostaNome] = useState(''); //nome da proposta
    const [tipoProposta, setTipoProposta] = useState('proposta_cliente'); // tipo da Proposta


    useEffect(() => {
        // Verifica se todos os campos do FormCalculoProposta estão preenchidos
        const isFormCalculoValid = Object.entries(isvIucData)
            .filter(([key]) => {
                // Ignora os campos especificados
                if (key === 'tipoDeVeiculoParaInsencoes' || key === 'outrosServicos' || key === 'hibridoMais25kmAutonomia' || key === 'dataPrimeiroRegisto') {
                    return false;
                }
    
                // Se o tipo de combustível for "Eletrico", ignora os campos 'cilindrada' e 'emissoesCO2'
                if (isvIucData.tipoCombustivel === 'Eletrico' && (key === 'cilindrada' || key === 'emissoesCO2' || key === "particulas")) {
                    return false;
                }
    
                return true;
            })
            .every(([, value]) => value !== '' && value !== null && value !== undefined);
        
        // Habilita o botão apenas se o nome da proposta e todos os campos do FormCalculoProposta estiverem preenchidos
        setIsButtonDisabled(!(propostaNome && isFormCalculoValid));
    }, [propostaNome, isvIucData]);
    


    const handleSubmit = () => {

        // let listaDeDadosAtualizados = {

        //     //isvIucData.tipoCombustivel Se for eletrico wltp e hibrido ficam a 0

        //     cilindrada: isvIucData.cilindrada,
        //     novo: isvIucData.novoUsado == "Usado" ? 0 : 1,
        //     tipo_de_combustivel: isvIucData.tipoCombustivel,
        //     emissoes_numerico: isvIucData.emissoesCO2,
        //     data_registo: isvIucData.dataPrimeiroRegisto,
        //     particulas: isvIucData.particulas,
        //     wltp: isvIucData.wltp == false ? 0 : 1,
        //     preco_final_carro: valoresTotais.precoViaturaComIva,
        //     preco_final_carro_sem_iva: valoresTotais.precoViaturaSemIva,
        //     tipo_de_veiculo: isvIucData.tipoDeVeiculoParaOutros,
        //     tipo_de_isencoes: isvIucData.tipoDeVeiculoParaInsencoes,
        //     hibrido: isvIucData.hibridoPlugIn ? 1 : 0,
        //     // outros_servicos: outrosServicos,

        //     iuc: valoresTotais.valorTotalIUC,
        //     iuc_cilindrada: valoresTotais.compsIUC.iucCilindrada,
        //     iuc_co2: valoresTotais.compsIUC.iucCO2,
        //     iuc_adicional_co2: valoresTotais.compsIUC.iucAdicionalCO2,
        //     iuc_coeficiente_ano_matriculo: valoresTotais.compsIUC.iucCoeficienteAnoMatriculo,
        //     iuc_adicional_gasoleo: valoresTotais.compsIUC.iucAdicionalGasoleo,

        //     isv: valoresTotais.valorTotalISV,
        //     isv_componente_cilindrada: valoresTotais.compsISV.isvcomponentecilindrada,
        //     isv_componente_ambiental: valoresTotais.compsISV.isvcomponenteambiental,
        //     isv_reducao_anos_uso_cilindrada: valoresTotais.compsISV.isvReducaoAnosUsoCilindrada,
        //     isv_reducao_anos_uso_ambiental: valoresTotais.compsISV.isvReducaoAnosUsoAmbiental,
        //     isv_agravamento_particulas: valoresTotais.compsISV.isvAgravamentoParticulas,
        //     isv_reducao_anos_uso_particulas: valoresTotais.compsISV.isvReducaoAnosUsoParticulas,
        //     isv_taxa_aplicavel_tabela: valoresTotais.compsISV.isvTaxaAplicavelTabela,

        //     taxadeservico: valoresTotais.taxaDeServico,
        //     comissaodeservico: valoresTotais.comissao,
        //     comissao_credito: valoresTotais.taxaDeFinanciamento,
        //     check_comissao_credito: valoresTotais.taxaDeFinanciamentoAtivo,
        //     // precofinal: valoresTotais, // ???
        //     valor_chavenamao_particular: valoresTotais.valorPrecoChaveNaMaoParticular,
        //     valor_chavenamao_empresa: valoresTotais.valorPrecoChaveNaMaoEmpresa,
        //     // empresa: tabResultadoSimulacao,
        // };

        let listaDeDadosAtualizados = {
            // Definindo valores específicos para tipo de combustível elétrico
            cilindrada: isvIucData.cilindrada,
            novo: isvIucData.novoUsado == "Usado" ? 0 : 1,
            tipo_de_combustivel: isvIucData.tipoCombustivel,
            emissoes_numerico: isvIucData.tipoCombustivel === "Eletrico" ? 0 : isvIucData.emissoesCO2,
            data_registo: isvIucData.dataPrimeiroRegisto,
            particulas: isvIucData.particulas,
            wltp: isvIucData.tipoCombustivel === "Eletrico" ? 0 : (isvIucData.wltp == false ? 0 : 1),
            preco_final_carro: valoresTotais.precoViaturaComIva,
            preco_final_carro_sem_iva: valoresTotais.precoViaturaSemIva,
            tipo_de_veiculo: isvIucData.tipoDeVeiculoParaOutros,
            tipo_de_isencoes: isvIucData.tipoDeVeiculoParaInsencoes,
            hibrido: isvIucData.tipoCombustivel === "Eletrico" ? 0 : (isvIucData.hibridoPlugIn ? 1 : 0),
            hibrido_mais_25km_autonomia: isvIucData.hibridoMais25kmAutonomia == true ? 1 : 0,

            iuc: valoresTotais.valorTotalIUC,
            iuc_cilindrada: valoresTotais.compsIUC.iucCilindrada,
            iuc_co2: valoresTotais.compsIUC.iucCO2,
            iuc_adicional_co2: valoresTotais.compsIUC.iucAdicionalCO2,
            iuc_coeficiente_ano_matriculo: valoresTotais.compsIUC.iucCoeficienteAnoMatriculo,
            iuc_adicional_gasoleo: valoresTotais.compsIUC.iucAdicionalGasoleo,
        
            isv: valoresTotais.valorTotalISV,
            // isv_2025: valoresTotais.valorTotalISV2025,
            isv_componente_cilindrada: valoresTotais.compsISV.isvcomponentecilindrada,
            isv_componente_ambiental: valoresTotais.compsISV.isvcomponenteambiental,
            isv_reducao_anos_uso_cilindrada: valoresTotais.compsISV.isvReducaoAnosUsoCilindrada,
            isv_reducao_anos_uso_ambiental: valoresTotais.compsISV.isvReducaoAnosUsoAmbiental,
            isv_agravamento_particulas: valoresTotais.compsISV.isvAgravamentoParticulas,
            isv_reducao_anos_uso_particulas: valoresTotais.compsISV.isvReducaoAnosUsoParticulas,
            isv_taxa_aplicavel_tabela: valoresTotais.compsISV.isvTaxaAplicavelTabela,
        
            taxadeservico: valoresTotais.taxaDeServico,
            comissaodeservico: valoresTotais.comissao,
            comissao_credito: valoresTotais.taxaDeFinanciamento,
            check_comissao_credito: valoresTotais.taxaDeFinanciamentoAtivo,
            comissao_express: valoresTotais.valorExpress,
            check_comissao_express: valoresTotais.valorExpressAtivo,
            valor_chavenamao_particular: valoresTotais.valorPrecoChaveNaMaoParticular,
            valor_chavenamao_empresa: valoresTotais.valorPrecoChaveNaMaoEmpresa,

            caucao_comissao_credito: valoresTotais.caucaoFinanciamento,
            caucao_iva_pais_origem: valoresTotais.caucaoIvaPaisOrigem,


        };
        

        const dataCriarLink = {
            n: propostaNome,
            tipo_proposta: tipoProposta,
            // estado_proposta_hubspot: 'nao_associada',
            link_token: linkToken,
            user_id: dataUser.iDTokenAuthenticated,
            user_token: dataUser.saveToken,
            // id_deal: dataUser.idDoDealAssociar,
            array_dados_atualizados: listaDeDadosAtualizados,
        };


        axios.post(`${process.env.REACT_APP_CREATE_PROPOSTA_PT}`, dataCriarLink)
            .then((response) => {

               
                if (response.data.sucesso) {
                    const nomeGerado = response.data.data.url_acesso
                    // console.log(response)

                    navigate(`/${t('menu.proposta')
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/ /g, '-')}/?n=${nomeGerado}&new=true`);                
                } else {
                    message.warning(response.data.mensagem)
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };


    const voltarAPesquisar = () => {
        setUrlCarroLink('');
    };

    return (
        <div className="w-full flex gap-4 items-center justify-between">
            <div className="flex gap-4 items-center">
                <Button
                    type="primary"
                    htmlType="submit"
                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                    onClick={voltarAPesquisar}
                >
                    <HiRefresh /> Voltar a Pesquisar
                </Button>
            </div>
            <Form layout="vertical" form={form}>
                <div className="form-gerar-proposta" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>

                    <Form.Item
                        rules={[{ required: true, message: 'Por favor, insira o nome da proposta!' }]}
                    >
                        <Input
                            value={propostaNome}
                            onChange={(e) => setPropostaNome(e.target.value)}
                            placeholder="Insere o nome da proposta..."
                        />
                    </Form.Item>

                    <Form.Item>
                        <Select
                            value={tipoProposta}
                            onChange={(value) => setTipoProposta(value)}
                        >
                            <Option value="proposta_cliente">Proposta Cliente</Option> 
                            <Option value="proposta_interna">Proposta Interna</Option>
                            <Option value="proposta_de_content">Proposta Content</Option> 
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={isButtonDisabled}
                            className='flex gap-2 items-center bg-white text-black font-bold rounded'
                        >
                            Criar Proposta
                        </Button>
                    </Form.Item>

                </div>
            </Form>
        </div>
    );
};

export default RegistarPropostaForm;
