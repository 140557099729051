import React, { useState } from 'react';
import { FiDownload } from "react-icons/fi";
import { Spin, Button, message } from 'antd';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function DownloadDoPrintAnuncio({ dataUser, propostaInfo, setPropostaInfo, isSuccessPrintAnuncio }) {

    //console.log(propostaInfo)
    const { t } = useTranslation();
    const [loadingPrint, setLoadingPrint] = useState(false)


    const handleDownloadFicheiros = async (fileNames) => {
        // Se fileNames for uma string, transforma-o em um array com um único elemento
        if (typeof fileNames === 'string') {
            fileNames = [fileNames];
        }

        try {
            for (const file of fileNames) {
                // Certificar-se de que estamos usando o nome correto do arquivo
                const fileName = typeof file === 'string' ? file : file.nome;

                // Fazer a requisição para o download do arquivo atual
                const response = await axios.get(`${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`, {
                    params: {
                        token: dataUser.saveToken,
                        user_id: dataUser.iDTokenAuthenticated,
                        nome_ficheiro: fileName,
                        id_proposta: propostaInfo.idProposta,
                        tipo: 'print_proposta'
                    },
                    responseType: 'blob' // Para lidar com o download de arquivos
                });

                // Criar um link temporário para fazer o download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // Usar o nome correto do arquivo

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Remover o link do DOM
                window.URL.revokeObjectURL(url); // Liberar o objeto URL
                message.success(t('download_print_anuncio.download_do_ficheiro_com_sucesso'));
            }
        } catch (error) {
            console.error('Erro ao fazer download do arquivo:', error);
            message.error(t('download_print_anuncio.erro_ao_fazer_download'));
        }
    };



    const handleForcarPrintAnuncio = () => {

        message.warning(t('download_print_anuncio.aguarda_por_favor_processo_demora_uns_segundos'))
        setLoadingPrint(true)

        const dataForcarPrintAnuncio = {
            id_proposta: propostaInfo.idProposta,
            user_id: dataUser.iDTokenAuthenticated,
            user_token: dataUser.saveToken,
            nome_ficheiro: propostaInfo.imagensParaDownload,
        };

        axios.post(`${process.env.REACT_APP_FORCAR_PRINT_ANUNCIO}`, dataForcarPrintAnuncio)
            .then((response) => {

                if (response.data.sucesso) {
                    console.log(response)
                    message.success(response.data.mensagem)

                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        printAnuncioParaDownload: response.data.print_proposta
                    }));

                    setLoadingPrint(false)
                } else {
                    message.warning(response.data.mensagem)
                }

            })

            .catch((error) => {
                console.log(error)
            })

            .finally(() => {
                setLoadingPrint(false)
            });

    }


    console.log(propostaInfo.printAnuncioParaDownload)

    return (
        <>



            {propostaInfo.printAnuncioParaDownload ? (
                <div className='flex justify-between items-center'>
                    <p className="text-black text-base">
                        {t('download_print_anuncio.print_do_anuncio')}
                    </p>
                    <Button
                        type="primary"
                        target="_blank"
                        className="flex gap-2 items-center bg-white text-black font-bold rounded"
                        onClick={() => handleDownloadFicheiros(propostaInfo.printAnuncioParaDownload)}
                    >
                        <FiDownload />
                        Download
                    </Button>
                </div>
            ) : (
                <div className='flex justify-between items-center'>
                    <p className="text-black text-base">
                        {t('download_print_anuncio.forcar_print_do_anuncio')}
                    </p>
                    {loadingPrint ? (
                        <>
                            <div className="flex gap-2 justify-center items-center font-bold text-black">
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: "black" }} spin />} />
                                {t('download_print_anuncio.a_gerar_print_aguarda')}
                            </div>
                        </>
                    ) : (
                        <>
                            <Button
                                type="primary"
                                target="_blank"
                                className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                onClick={handleForcarPrintAnuncio}
                                disabled={!isSuccessPrintAnuncio}
                            >
                                <div className='flex items-center gap-2'>
                                    <FiDownload />
                                    {t('download_print_anuncio.forcar')}
                                    {!isSuccessPrintAnuncio && (
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: "#555555" }} spin />} />
                                    )}
                                </div>
                            </Button>
                        </>
                    )}
                </div>
            )}





        </>
    );
}
