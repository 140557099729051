import React, { useEffect, useState } from "react";
import TitleSection from "../../components/Elements/Title";
import axios from "axios";
import { Form, Button, message } from "antd";
import FormSelectsGerarDocumentos from "../../components/GeradorDocumentos/GerarDocumentos/FormSelectsGerarDocumentos";
import TodosCamposGerarDocumentos from "../../components/GeradorDocumentos/GerarDocumentos/TodosCamposGerarDocumentos";
import DownloadDocumentos from "../../components/GeradorDocumentos/GerarDocumentos/DownloadDocumentos";
import { BiReset } from "react-icons/bi";
import Loading from "../../components/Elements/Loading";
import { Link } from "react-router-dom";

export default function GerarDocumentos({ dataUser }) {
    const [formSelects] = Form.useForm();
    const [formFields] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [isContratoVisible, setIsContratoVisible] = useState(false);

    const [data, setData] = useState({
        tipos_documentos: [],
        tipo_documento_selecionado: null,
        tipos_sociedades: [],
        tipo_sociedade_selecionado: null,
        tipos_contratos: [],
        tipo_contrato_selecionado: null,
        idProposta: null,
        idDeal: null,
        fields: {},
        otherDocs: {},
        linkDownload: []
    });

    const [dataProposta, setDataProposta] = useState({
        listaDeContactos: [],
        contactoSelecionado: '',
        pesquisaProposta: '',
        dadosDaPropostaSelecionada: []
    });

    const editarDocumento = new URLSearchParams(window.location.search).get("editar_id_documento");
    // const gerarDocumento = new URLSearchParams(window.location.search).get("gerar_id_documento");

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_GET_SELECT_DOCUMENTOS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}`;
            try {
                const response = await axios.get(url);
                if (response.data.sucesso) {
                    setData(prev => ({
                        ...prev,
                        tipos_documentos: response.data.data.tipos_documentos,
                        tipos_sociedades: response.data.data.tipos_sociedades,
                        tipos_contratos: response.data.data.tipos_contratos
                    }));
                } else {
                    message.error(response.data.mensagem || "Erro ao carregar dados iniciais.");
                }
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
                message.error("Ocorreu um erro ao procurar pelos dados.");
            }
        };


        fetchData();

    }, [dataUser]);

    useEffect(() => {
        const fetchGetDocumento = async () => {
            const url = `${process.env.REACT_APP_GET_EDITAR_DOCUMENTOS}?token=${dataUser.saveToken}&user_id=${dataUser.iDTokenAuthenticated}&lang=${dataUser.language}&id_documento=${editarDocumento}`;
            try {
                const response = await axios.get(url);
                if (response.data.sucesso) {
                    const { id_deal, tipo_documento, tipo_sociedade, tipo_contrato, doc } = response.data.data;

                    setData(prev => ({
                        ...prev,
                        idDeal: id_deal,
                        tipo_documento_selecionado: tipo_documento,
                        tipo_sociedade_selecionado: tipo_sociedade,
                        tipo_contrato_selecionado: tipo_contrato,
                        fields: doc
                    }));

                    formSelects.setFieldsValue({
                        propostaAceite: id_deal,
                        tipoDocumento: tipo_documento,
                        tipoSociedade: tipo_sociedade,
                        tipoContrato: tipo_contrato
                    });

                    if (tipo_contrato) setIsContratoVisible(true);
                } else {
                    message.error(response.data.mensagem || "Erro ao carregar documento.");
                }
            } catch (error) {
                console.error("Erro ao buscar documento:", error);
                message.error("Ocorreu um erro ao procurar pelos dados.");
            }
        };

        if (editarDocumento && data.tipos_documentos.length && data.tipos_sociedades.length) {
            fetchGetDocumento();
        }
    }, [editarDocumento, data.tipos_documentos, data.tipos_sociedades, dataUser]);

    const handleFormChange = updatedData => {
        setData(prev => ({
            ...prev,
            ...updatedData,
            linkDownload: []
        }));
    };

    const handleReset = () => {
        formSelects.resetFields();
        formFields.resetFields();

        setData(prev => ({
            ...prev,
            tipo_documento_selecionado: null,
            tipo_sociedade_selecionado: null,
            tipo_contrato_selecionado: null,
            idProposta: null,
            idDeal: null,
            fields: {},
            otherDocs: {},
            linkDownload: []
        }));


        setDataProposta(prev => ({
            ...prev,
            listaDeContactos: [],
            contactoSelecionado: '',
            pesquisaProposta: '',
            dadosDaPropostaSelecionada: []
        }));

        // Atualiza os valores no formulário para refletirem o reset
        formSelects.setFieldsValue({
            tipoDocumento: '',
            tipoSociedade: '',
            tipoContrato: '',
            propostaAceite: ''
        });

        setIsContratoVisible(false);
        // message.success("Formulário redefinido com sucesso.");
    };


    return (
        <>
            <div className="card grid gap-4">

                <TitleSection
                    title={editarDocumento ? "Editar Documento" : "Gerar Documento"}
                    description={editarDocumento ? "Edita o documento de forma rápida." : "Gera o documento de forma rápida."}
                />

                {!Boolean(data.linkDownload?.length) && (
                    <FormSelectsGerarDocumentos
                        formSelects={formSelects}
                        setIsContratoVisible={setIsContratoVisible}
                        isContratoVisible={isContratoVisible}
                        data={data}
                        dataUser={dataUser}
                        onFormChange={handleFormChange}
                        setData={setData}
                        setLoading={setLoading}
                        dataProposta={dataProposta}
                        setDataProposta={setDataProposta}
                        editarDocumento={editarDocumento}
                    />
                )}


                {editarDocumento ? (
                    <>
                        <div className="flex items-center justify-end">
                            <Link to={'/gerar-documentos'}>

                                <Button
                                    className="flex items-center shadow-none font-bold text-black bg-white"
                                    icon={<BiReset />}
                                    onClick={handleReset}
                                >
                                    Gerar novo
                                </Button>
                            </Link>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex items-center justify-end">
                            <Button
                                className="flex items-center shadow-none font-bold text-black bg-white"
                                icon={<BiReset />}
                                onClick={handleReset}
                            >
                                {data.linkDownload && data.linkDownload.length > 0 ? (
                                    <>
                                        Gerar novo
                                    </>
                                ) : (
                                    <>
                                        Reset ao Formulário
                                    </>
                                )}
                            </Button>
                        </div>
                    </>
                )}
            </div >


            {Object.keys(data.fields).length > 0 && (
                <TodosCamposGerarDocumentos
                    editarDocumento={editarDocumento}
                    fields={data.fields}
                    otherDocs={data.otherDocs}
                    data={data}
                    setData={setData}
                    dataUser={dataUser}
                    formFields={formFields}
                    setLoading={setLoading}
                />
            )}

            {data.linkDownload && data.linkDownload.length > 0 && (
                <>
                    <DownloadDocumentos linkDownload={data.linkDownload} />
                </>
            )}


            {loading && (
                <Loading />
            )}


            <div className="grid items-center justify-items-center gap-4">
                <p className="text-white text-base font-semibold">Se encontrares algum problema usa a versão antiga!</p>
                <Link to="/gerador-documentos-old">
                    <Button
                        type="primary"
                        className='shadow-none font-bold text-black bg-yellow-500'>
                        <div className='flex items-center gap-2'>
                            <p>Abrir</p>
                        </div>
                    </Button>
                </Link>
            </div>


        </>
    );
}
