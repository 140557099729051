import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DocumentTabs from './DocumentTabs';
import DownloadDocumentos from './DownloadDocumentos';
dayjs.extend(customParseFormat);

export default function TodosCamposGerarDocumentos({
    editarDocumento,
    fields,
    data,
    setData,
    dataUser,
    setLoading
}) {

    return (
        <>
            {/* {loading && (
                <p className='text-white'>asda</p>
            )} */}
            <div className="card grid gap-4">
                <DocumentTabs
                    editarDocumento={editarDocumento}
                    documents={fields}
                    dataUser={dataUser}
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                />
            </div>
        </>
    );
}
