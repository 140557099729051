import React, { useState } from 'react';
import { Button, Input, message, Form, Spin, Select } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 25,
        }}
        spin
    />
);



export default function FromEditarProposta({
    dataUser,
    propostaInfo,
    valoresTotais,
    setValoresTotais,
    isvIucData,
    setPropostaInfo
}) {

    const { t } = useTranslation();

    const [isvCalculado, setIsvCalculado] = useState(valoresTotais.valorTotalISV); // Estado local para armazenar o resultado do cálculo do ISV
    const [usouCalculo, setUsouCalculo] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [isCalculating, setIsCalculating] = useState(false); // Novo estado

    const [newTipoProposta, setNewTipoProposta] = useState(propostaInfo.tipoProposta); // tipo da Proposta


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };


    // Função para alterar os valores de todos os inputs
    const handleValueChange = (e) => {
        const { name, value } = e.target;
    
        // Converte para número, ou 0 caso não seja um número válido
        const numericValue = parseFloat(value) || 0;
    
        setValoresTotais((prevData) => ({
            ...prevData,
            [name]: numericValue // Garante que é armazenado como número
        }));
    };
    


    //console.log('valoresTotais', valoresTotais)

    // Função para calcular o ISV baseado nas emissões de CO2
    const handleNovasEmissoes = (e) => {
        setUsouCalculo(false);
        setIsCalculating(true); // Inicia o cálculo

        const novoValorEmissoes = e.target.value;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (novoValorEmissoes) {
            const newTimeoutId = setTimeout(() => {

                let pedido
                let data

                if (dataUser.language === 'pt') {
                    pedido = process.env.REACT_APP_PEDIDO_ISV

                    data = {
                        id_proposta: propostaInfo.idProposta,
                        novo: isvIucData.novoUsado,
                        tipo_de_testes_co2: isvIucData.wltp,
                        tipo_carro: isvIucData.tipoDeVeiculoParaOutros,
                        combustivel: isvIucData.tipoCombustivel,
                        particulas: isvIucData.tipoCombustivel,
                        co2: novoValorEmissoes,
                        cilindrada: isvIucData.cilindrada,
                        ano: isvIucData.dataPrimeiroRegisto,
                        isencao: isvIucData.tipoDeVeiculoParaInsencoes,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                    };

                } else {
                    pedido = process.env.REACT_APP_PEDIDO_ISV_ES

                    data = {


                        cilindrada: isvIucData.cilindrada,
                        novo: isvIucData.novoUsado,
                        combustivel: isvIucData.tipoCombustivel,
                        co2: novoValorEmissoes,
                        ano: isvIucData.dataPrimeiroRegisto,
                        isencao: isvIucData.tipoDeVeiculoParaInsencoes,
                        id_modelo: isvIucData.idModelo,
                        id_cidade: isvIucData.provincia,
                        valor_viatura_nova: isvIucData.valorViaturaNova,

                        // tipo_de_testes_co2: isvIucData.wltp,
                        // tipo_carro: isvIucData.tipoDeVeiculoParaOutros,
                        // particulas: isvIucData.tipoCombustivel,

                        id_proposta: propostaInfo.idProposta,
                        user_id: dataUser.iDTokenAuthenticated,
                        token: dataUser.saveToken,
                    };

                    // console.log(data)
                }



                axios.post(pedido, data)
                    .then((response) => {
                        // console.log(response)
                        if (response.data.sucesso) {
                            setIsvCalculado(response.data.data.isv);
                            setValoresTotais((prevData) => ({
                                ...prevData,
                                valorTotalISV: response.data.data.isv
                            }));
                            setUsouCalculo(true);
                        } else {
                            message.error(response.data.mensagem);
                        }
                    })
                    .catch(() => {
                        message.error(t('editar_proposta.error'));
                    })
                    .finally(() => {
                        setIsCalculating(false); // Finaliza o cálculo
                    });

            }, 1000);

            setTimeoutId(newTimeoutId);
        } else {
            message.error(t('editar_proposta.error'));
            setIsCalculating(false); // Finaliza o cálculo mesmo em caso de erro
        }
    };

    // console.log(valoresTotais)

    // Função para finalizar a edição da proposta
    const finalizarEdiçãodeProposta = (values) => {


        console.log('valoresTotais', values)


        let dados;
        //console.log(values)
        setIsCalculating(true); // Inicia o cálculo

        // Certifique-se de que os valores são convertidos para números
        const precoViaturaComIva = parseFloat(values.precoViaturaComIva) || 0;
        const precoViaturaSemIva = parseFloat(values.precoViaturaSemIva) || 0;
        const taxaDeServico = parseFloat(values.taxaDeServico) || 0;        

        // Cálculo do total para "Chave na Mão" para particular
        const calculoTotalChaveNaMaoParticular = precoViaturaComIva + taxaDeServico + isvCalculado;
        //console.log("Total Chave na Mão Particular:", calculoTotalChaveNaMaoParticular);

        // Cálculo do total para "Chave na Mão" para empresa
        const calculoTotalChaveNaMaoEmpresa = precoViaturaSemIva + taxaDeServico + isvCalculado;
        // console.log("Total Chave na Mão Empresa:", calculoTotalChaveNaMaoEmpresa);

        if (dataUser.language === 'pt') {


            dados = {
                tipo_proposta: values.tipoProposta,
                emissoes_numerico: values.emissoesCO2, // Pega o valor atualizado
                taxadeservico: values.taxaDeServico,
                valor_chavenamao_particular: calculoTotalChaveNaMaoParticular,
                valor_chavenamao_empresa: calculoTotalChaveNaMaoEmpresa,
                preco_final_carro: values.precoViaturaComIva,
                preco_final_carro_sem_iva: values.precoViaturaSemIva,
                isv: isvCalculado,
                isv_componente_cilindrada: valoresTotais.compsISV.isvcomponentecilindrada,
                isv_componente_ambiental: valoresTotais.compsISV.isvcomponenteambiental,
                isv_reducao_anos_uso_cilindrada: valoresTotais.compsISV.isvReducaoAnosUsoCilindrada,
                isv_reducao_anos_uso_ambiental: valoresTotais.compsISV.isvReducaoAnosUsoAmbiental,
                isv_agravamento_particulas: valoresTotais.compsISV.isvAgravamentoParticulas,
                isv_reducao_anos_uso_particulas: valoresTotais.compsISV.isvReducaoAnosUsoParticulas,
                isv_taxa_aplicavel_tabela: valoresTotais.compsISV.isvTaxaAplicavelTabela,
            };

        } else {
            dados = {
                emissoes_numerico: values.emissoesCO2, // Pega o valor atualizado
                taxadeservico: values.taxaDeServico,
                valor_chavenamao_particular: calculoTotalChaveNaMaoParticular,
                valor_chavenamao_empresa: calculoTotalChaveNaMaoEmpresa,
                preco_final_carro: values.precoViaturaComIva,
                preco_final_carro_sem_iva: values.precoViaturaSemIva,
                isv: isvCalculado,


                isv_base_imponible: valoresTotais.compsISV.isvBaseImponible,
                isv_base_imponible_final: valoresTotais.compsISV.isvBaseImponibleFinal,
                isv_comunidad: valoresTotais.compsISV.isvComunidad,
                isv_cuota_tributaria: valoresTotais.compsISV.isvCuotaTributaria,
                isv_minoracion: valoresTotais.compsISV.isvMinoracion,
                isv_porcentaje_aplicar: valoresTotais.compsISV.isvPorcentajeAplicar,
                isv_reduccion_base_imponible: valoresTotais.compsISV.isvReduccionBaseImponible,
                isv_total_pagar: valoresTotais.compsISV.isvTotalPagar,
                isv_valoracion_inicial: valoresTotais.compsISV.isvValoracionInicial,
            };
        }

  

        const data = {
            id_proposta: propostaInfo.idProposta,
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
            array_dados: dados,
        };


        axios.post(`${process.env.REACT_APP_URL_EDITAR_PROPOSTA}`, data)
            .then((response) => {
                // console.log(response)
                if (response.data.sucesso) {
                    message.success(response.data.mensagem);
                    setPropostaInfo((prevData) => ({
                        ...prevData,
                        tipoProposta: values.tipoProposta,
                    }))
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch(() => {
                message.error(t('editar_proposta.error'));
            })
            .finally(() => {
                setIsCalculating(false); // Finaliza o cálculo
            });
    };


    return (
        <Form
            layout="vertical"
            onFinish={finalizarEdiçãodeProposta}
            initialValues={{
                tipoProposta: newTipoProposta,
                taxaDeServico: valoresTotais.taxaDeServico,
                precoViaturaComIva: valoresTotais.precoViaturaComIva,
                precoViaturaSemIva: valoresTotais.precoViaturaSemIva,
                emissoesCO2: isvIucData.emissoesCO2,
            }}
        >

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('tables.tipo')}</p>
                <Form.Item
                    name="tipoProposta"
                >
                    <Select
                        placeholder={t('tables.tipo')}
                        onChange={(value) => setNewTipoProposta(value)}
                    >
                        <Select.Option value="proposta_cliente">{t('tables.proposta_cliente')}</Select.Option>
                        <Select.Option value="proposta_interna">{t('tables.proposta_interna')}</Select.Option>
                        <Select.Option value="proposta_de_content">{t('tables.proposta_de_content')}</Select.Option>
                    </Select>
                </Form.Item>

            </div>

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.taxa_servico')}</p>
                <Form.Item name="taxaDeServico">
                    <Input
                        placeholder={t('editar_proposta.taxa_servico')}
                        value={valoresTotais.taxaDeServico}
                        onChange={handleValueChange}
                        name="taxaDeServico"
                        type="number"
                    />
                </Form.Item>
            </div>

            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.valor_v_particular')}</p>
                <Form.Item name="precoViaturaComIva">
                    <Input
                        placeholder={t('editar_proposta.valor_v_particular')}
                        value={valoresTotais.precoViaturaComIva}
                        onChange={handleValueChange}
                        name="precoViaturaComIva"
                        type="number"
                    />
                </Form.Item>
            </div>


            <div className='flex flex-col items-stretch gap-2'>
                <p className='font-semibold'>{t('editar_proposta.valor_v_empresa')}</p>
                <Form.Item name="precoViaturaSemIva">
                    <Input
                        placeholder={t('editar_proposta.valor_v_empresa')}
                        value={valoresTotais.precoViaturaSemIva}
                        onChange={handleValueChange}
                        name="precoViaturaSemIva"
                        type="number"
                    />
                </Form.Item>
            </div>
            {isvIucData.tipoCombustivel !== "Eletrico" && (
                <>
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='font-semibold'>{t('editar_proposta.emissoes')}</p>
                        <Form.Item name="emissoesCO2">
                            <Input
                                placeholder={t('editar_proposta.emissoes')}
                                value={isvIucData.emissoesCO2} // Valor atual de emissoes CO2
                                onChange={handleNovasEmissoes} // Calcula o ISV no onChange
                                name="emissoesCO2"
                                type="number"
                            />
                            {/* {isvCalculado && <p>ISV: {isvCalculado} €</p>} */}
                        </Form.Item>
                    </div>
                    {usouCalculo && (
                        <div className='flex flex-col items-stretch gap-2 mb-6'>
                            <p className='font-semibold'>{t('tables.isv')}</p>
                            <Input
                                placeholder={t('tables.isv')}
                                value={isvCalculado} // Valor atual de emissoes CO2
                                onChange={(e) => setIsvCalculado(e.target.value)} // Permitir edição manual do ISV
                                type="number"
                            />
                        </div>
                    )}
                </>
            )}
            <Form.Item>
                {!isCalculating ? ( // Mostra o botão apenas se não estiver calculando
                    <Button type="primary" htmlType="submit" className="shadow-none font-bold text-black bg-green-400">
                        {t('editar_proposta.guardar')}
                    </Button>
                ) : (
                    <Spin indicator={antIcon} />
                )}
            </Form.Item>
        </Form>
    );
}
