import React, { useState } from "react";
import { Select, Form, message } from "antd";
import axios from "axios";
import Loading from "../../../components/Elements/Loading";
import PesquisaPorPropostaAceite from "../PesquisaPorPropostaAceite/PesquisaPorPropostaAceite";

const { Option } = Select;

export default function FormSelectsGerarDocumentos({
    data,
    setData,
    dataUser,
    onFormChange,
    isContratoVisible,
    setIsContratoVisible,
    formSelects,
    setLoading,
    dataProposta,
    setDataProposta,
    editarDocumento
}) {

    const [openModal, setOpenModal] = useState(false);

    const handleFormChange = (_, allValues) => {

        const { tipoDocumento, tipoSociedade, tipoContrato, propostaAceite } = allValues;

        setData((prevState) => ({
            ...prevState,
            tipo_documento_selecionado: prevState.tipo_documento_selecionado || tipoDocumento,
            tipo_sociedade_selecionado: prevState.tipo_sociedade_selecionado || tipoSociedade,
            tipo_contrato_selecionado: prevState.tipo_contrato_selecionado || tipoContrato,
        }));

        // Reset `data.fields` when `tipoDocumento` changes
        if (tipoDocumento !== data.currentTipoDocumento) {
            onFormChange({ fields: [] }); // Reseta os campos ao alterar `tipoDocumento`
            data.currentTipoDocumento = tipoDocumento; // Atualiza o documento atual para comparações futuras
            setData((prevState) => ({
                ...prevState,
                linkDownload: []
            }));
        }

        // Exibe o select de contratos apenas se o tipo de documento for 'contrato' ou 'novo_negocio'
        if (tipoDocumento === 'contrato' || tipoDocumento === 'novo_negocio') {
            setIsContratoVisible(true);
            // message.warning('ID do Deal é obrigatório.');
        } else {
            setIsContratoVisible(false);
            // message.warning('ID do Deal é obrigatório.');
            formSelects.setFieldsValue({ tipoContrato: '' }); // Reseta o campo de contrato se o documento não for 'contrato'
        }

        if (!propostaAceite) {
            message.warning('ID do Deal é obrigatório.');
        }

        // Condicional para incluir ou excluir o tipoContrato no pedido
        // tipoContrato deve ser enviado como null se o tipoDocumento não for 'contrato'
        if (tipoDocumento && tipoSociedade && propostaAceite && (tipoDocumento !== 'contrato' || tipoContrato)) {

            setLoading(true)

            // Todos os campos obrigatórios estão preenchidos
            const dataGerarDocumentos = {
                id_deal: propostaAceite !== "" ? propostaAceite : null,
                tipo_documento: tipoDocumento,
                tipo_sociedade: tipoSociedade,
                tipo_contrato: tipoDocumento === 'contrato' || tipoDocumento === 'novo_negocio' ? tipoContrato : null, // Se não for contrato, enviar null
                user_id: dataUser.iDTokenAuthenticated,
                user_token: dataUser.saveToken,
            };

            axios.get(`${process.env.REACT_APP_GET_CAMPOS_DOCUMENTOS}`, {
                params: dataGerarDocumentos // Passa os parâmetros via query string
            })
                .then((response) => {
                    // console.log(response);
                    if (response.data.sucesso) {
                        // Atualiza os campos no componente pai, mantendo os valores anteriores
                        // console.log(response.data.data)
                        onFormChange({
                            fields: response.data.data.doc,
                            // otherDocs: response.data.data.doc.gera_outros_docs || []
                        });

                    } else {
                        message.error(response.data.mensagem);
                    }
                })
                .catch((error) => {
                    message.error('Erro. Tente novamente!');
                })
                .finally(() => {
                    setLoading(false); // Finaliza o loading em todos os casos
                });
        }

        onFormChange(allValues);
    };


    return (
        <>
            <Form
                form={formSelects}
                initialValues={{
                    tipoDocumento: data.tipo_documento_selecionado || '',
                    tipoSociedade: data.tipo_sociedade_selecionado || '',
                    tipoContrato: data.tipo_contrato_selecionado || '',
                    propostaAceite: '', // Define um valor inicial para o campo
                }}
                onValuesChange={(changedValues, allValues) => {
                    handleFormChange(changedValues, allValues);
                }}
            >
                {Array.isArray(data.tipos_documentos) && data.tipos_documentos.length > 0 && (
                    <div className={`flex gap-4 transition-all duration-500`} style={{ flexWrap: 'wrap' }}>

                        <PesquisaPorPropostaAceite
                            // data={data}
                            setData={setData}
                            formSelects={formSelects}
                            dataUser={dataUser}
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            onFormChange={handleFormChange}
                            dataProposta={dataProposta}
                            setDataProposta={setDataProposta}
                            editarDocumento={editarDocumento}
                        />


                        <div className='flex flex-col items-stretch justify-between gap-2 flex-1 transition-all duration-500'>
                            <p className='text-white font-semibold'>Selecione o Tipo de Documento *</p>
                            <Form.Item
                                name="tipoDocumento"
                                rules={[{ required: true, message: "Selecione um documento" }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecione um documento"
                                    disabled={editarDocumento}
                                >
                                    {data.tipos_documentos.map((doc) => (
                                        <Option key={doc.slug} value={doc.slug}>
                                            {doc.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div className='flex flex-col items-stretch justify-between gap-2 flex-1 transition-all duration-500'>
                            <p className='text-white font-semibold'>Selecione o Tipo de Sociedade *</p>
                            <Form.Item
                                name="tipoSociedade"
                                rules={[{ required: true, message: "Selecione uma sociedade" }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Selecione uma sociedade"
                                    disabled={editarDocumento}
                                >
                                    {data.tipos_sociedades.map((soc) => (
                                        <Option key={soc.slug} value={soc.slug}>
                                            {soc.nome}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        {isContratoVisible && (
                            <div
                                className='flex flex-col items-stretch justify-between gap-2 flex-1 transition-all duration-500'
                                style={{ opacity: isContratoVisible ? 1 : 0, maxWidth: isContratoVisible ? '100%' : 0, overflow: 'hidden' }}
                            >
                                <p className='text-white font-semibold'>Selecione o Tipo de Contrato *</p>
                                <Form.Item
                                    name="tipoContrato"
                                    rules={[{ required: isContratoVisible, message: "Selecione um contrato" }]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Selecione um contrato"
                                        disabled={editarDocumento}
                                    >
                                        {data.tipos_contratos.map((contrato) => (
                                            <Option key={contrato.slug} value={contrato.slug}>
                                                {contrato.nome}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        )}


                    </div>
                )}

            </Form>
        </>
    );
};
